.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex ;
    flex-direction: column;
    align-items: center;
    animation: bouncing 2s ease-in-out infinite !important;
    
  }
  .anchor-whatsapp{
    background: none !important;
  }


  
  .btn-whatsapp {
    background-color: transparent !important;
    background-image: url('../assets/icons/whatsapp.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    /* border-radius: 50%; */
    border: none;
    width: 55px;
    height: 55px;
    font-size: 20px;
  }

  @media (max-width:768px) {
    .text-whatsapp{
      display: none;
    }
  }


  @keyframes bouncing {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }